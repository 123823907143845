<template>
	<NavigationBar />

	<q-page-container>
		<q-page padding>
			<div class="row">
				<div>
					<q-btn
						dense
						flat
						icon="arrow_back"
						@click="
							this.$router.push({
								name: 'Customeraccounts',
							})
						"
					/>
				</div>
				<div class="col-7">
					<PieChart :chartData="this.chartData" />
				</div>
				<div class="col-4">
					<q-card flat bordered class="my-card">
						<q-card-section>
							<div class="text-h6">
								#{{ customeraccount.number }} |
								<span v-if="customeraccount.type == 'b2b'">
									{{ customeraccount.organisation }}
								</span>
								<span v-else>
									{{
										customeraccount.firstname +
										' ' +
										customeraccount.lastname
									}}
								</span>
							</div>
						</q-card-section>

						<q-card-section class="q-pt-none">
							<q-list dense>
								<q-item>
									<q-item-section>
										<q-item-label>Type</q-item-label>
									</q-item-section>
									<q-item-section side>
										<q-item-label caption>{{
											customeraccount.type
										}}</q-item-label>
									</q-item-section>
								</q-item>
								<q-item>
									<q-item-section>
										<q-item-label>Name</q-item-label>
									</q-item-section>
									<q-item-section side>
										<q-item-label caption>{{
											customeraccount.firstname +
											' ' +
											customeraccount.lastname
										}}</q-item-label>
									</q-item-section>
								</q-item>
								<q-item>
									<q-item-section>
										<q-item-label>E-Mail</q-item-label>
									</q-item-section>
									<q-item-section side>
										<q-item-label caption>{{
											customeraccount.email
										}}</q-item-label>
									</q-item-section>
								</q-item>
								<q-item>
									<q-item-section>
										<q-item-label
											>Organisation</q-item-label
										>
									</q-item-section>
									<q-item-section side>
										<q-item-label caption>{{
											customeraccount.organisation
										}}</q-item-label>
									</q-item-section>
								</q-item>
							</q-list>
						</q-card-section>
					</q-card>
				</div>
			</div>
			<div class="row">
				<div class="col-12">
					<q-table
						flat
						bordered
						title="Invoices"
						:rows="customeraccount.Invoices"
						:columns="invoicesTableConfiguration.columns"
						row-key="id"
						:pagination="
							invoicesTableConfiguration.initialPagination
						"
						:filter="invoicesTableConfiguration.filter"
						selection="multiple"
						v-model:selected="invoicesTableConfiguration.selected"
					>
						<template
							v-slot:top-left
							v-if="
								this.invoicesTableConfiguration.selected
									.length > 0
							"
						>
							<q-select
								filled
								v-model="
									invoicesTableConfiguration.selectedAction
								"
								:options="[
									{
										label: 'Send Mail',
										value: 'send_mail',
									},
								]"
								label="Action"
							>
								<template v-slot:after>
									<q-btn
										dense
										flat
										icon="send"
										@click="tableMultiAction"
									/>
								</template>
							</q-select>
						</template>

						<template v-slot:top-right>
							<q-input
								borderless
								dense
								debounce="300"
								v-model="invoicesTableConfiguration.filter"
								placeholder="Search"
							>
								<template v-slot:append>
									<q-icon name="search" />
								</template>
							</q-input>
						</template>

						<template v-slot:body-cell-status="props">
							<q-td :props="props">
								<q-badge
									v-if="props.row.status == 'paid'"
									color="green"
								>
									{{ $t(props.row.status) }}
								</q-badge>
								<q-badge
									v-else-if="props.row.status == 'open'"
									color="red"
								>
									{{ $t(props.row.status) }}
								</q-badge>
								<q-badge v-else color="grey">
									{{ $t(props.row.status) }}
								</q-badge>
							</q-td>
						</template>

						<template v-slot:body-cell-actions="props">
							<q-td :props="props">
								<q-btn
									flat
									@click="
										sendInvoiceMailDialog(
											props.row,
											customeraccount
										)
									"
								>
									<q-icon name="email" />
								</q-btn>
								<q-btn flat @click="viewInvoice(props.row)">
									<q-icon name="visibility" />
								</q-btn>

								<q-btn
									flat
									@click="
										openInvoiceDialog(
											props.row,
											customeraccount.id
										)
									"
								>
									<q-icon name="edit" />
								</q-btn>

								<q-btn flat @click="deleteInvoice(props.row)">
									<q-icon name="delete" />
								</q-btn>
							</q-td>
						</template>
					</q-table>
				</div>
			</div>
			<q-page-sticky position="bottom-right" :offset="[18, 18]">
				<!--<q-btn
					fab
					icon="add"
					color="primary"
					@click="openCustomeraccountDialog()"
				/>-->
				<q-fab
					vertical-actions-align="right"
					color="primary"
					glossy
					icon="keyboard_arrow_up"
					direction="up"
				>
					<!--<q-fab-action
						label-position="left"
						color="primary"
						@click="onClick"
						icon="mail"
						label="Email"
					/>-->
					<q-fab-action
						label-position="left"
						color="secondary"
						@click="onClick"
						icon="edit"
						label="Edit Account"
					/>
					<q-fab-action
						label-position="left"
						color="orange"
						@click="openInvoiceDialog()"
						icon="add"
						label="Add Invoice"
					/>
					<!--<q-fab-action
						label-position="left"
						color="accent"
						@click="onClick"
						icon="room"
						label="Map"
					/>-->
				</q-fab>
			</q-page-sticky>
			<q-dialog v-model="invoiceDialogSwitch">
				<q-card style="width: 700px; max-width: 80vw">
					<q-card-section class="row items-center q-pb-none">
						<div class="text-h6">Invoice</div>
						<q-space />
						<q-btn icon="close" flat round dense v-close-popup />
					</q-card-section>

					<q-card-section>
						<q-form
							@submit="submitInvoiceDialog"
							class="q-gutter-md"
						>
							<q-select
								standout
								v-model="invoiceDialog.status"
								:options="[
									{ label: 'Open', value: 'open' },
									{ label: 'Paid', value: 'paid' },
								]"
								label="Status"
								:rules="[
									(val) =>
										(val !== null && val !== '') ||
										'Please enter a status',
								]"
							/>
							<q-input
								filled
								v-model="invoiceDialog.number"
								label="Number"
								lazy-rules
								:rules="[
									(val) =>
										(val && val.length > 0) ||
										'Please type something',
								]"
							/>

							<q-input
								filled
								type="number"
								v-model="invoiceDialog.amount"
								label="Amount"
								lazy-rules
								step="any"
								:rules="[
									(val) =>
										(val !== null && val !== '') ||
										'Please type your age',
								]"
							/>

							<q-input
								filled
								v-model="invoiceDialog.billingdate"
								mask="date"
								label="Billingdate"
							>
								<template v-slot:append>
									<q-icon name="event" class="cursor-pointer">
										<q-popup-proxy
											cover
											transition-show="scale"
											transition-hide="scale"
										>
											<q-date
												v-model="
													invoiceDialog.billingdate
												"
											>
												<div
													class="row items-center justify-end"
												>
													<q-btn
														v-close-popup
														label="Close"
														color="primary"
														flat
													/>
												</div>
											</q-date>
										</q-popup-proxy>
									</q-icon>
								</template>
							</q-input>

							<q-input
								filled
								v-model="invoiceDialog.invoicedate"
								mask="date"
								:rules="['date']"
								label="Invoicedate"
							>
								<template v-slot:append>
									<q-icon name="event" class="cursor-pointer">
										<q-popup-proxy
											cover
											transition-show="scale"
											transition-hide="scale"
										>
											<q-date
												v-model="
													invoiceDialog.invoicedate
												"
											>
												<div
													class="row items-center justify-end"
												>
													<q-btn
														v-close-popup
														label="Close"
														color="primary"
														flat
													/>
												</div>
											</q-date>
										</q-popup-proxy>
									</q-icon>
								</template>
							</q-input>

							<q-file
								outlined
								v-model="invoiceDialog.file"
								filled
								label="File"
								:rules="[
									(val) =>
										(val !== null && val !== '') ||
										'Please enter a file',
								]"
							>
								<template v-slot:append>
									<q-icon name="attach_file" />
								</template>
							</q-file>

							<div>
								<q-btn
									label="Submit"
									type="submit"
									color="primary"
								/>
							</div>
						</q-form>
					</q-card-section>
				</q-card>
			</q-dialog>
			<q-dialog v-model="invoiceMailSendingDialogSwitch" persistent>
				<q-card>
					<q-card-section class="row items-center">
						<p class="dark:text-white">
							Möchtest du wirklich die Email an
							<b>{{ invoiceMailSendingPrompt.email }}</b> senden?
						</p>
						<br />
						<p
							v-if="
								invoiceMailSendingPrompt.always_sended_single_count >
								0
							"
							class="dark:text-white"
						>
							Die Rechnung wurde bereits
							{{
								invoiceMailSendingPrompt.always_sended_single_count
							}}
							mal versendet.
						</p>
						<p
							v-if="
								invoiceMailSendingPrompt.always_sended_multi_count >
								0
							"
							class="dark:text-white"
						>
							Einige Rechnungen wurden bereits gesendet.
						</p>
					</q-card-section>

					<q-card-actions align="right">
						<q-btn
							flat
							label="No"
							color="primary"
							v-close-popup
							@click="this.invoiceMailSendingDialogSwitch = false"
						/>
						<q-btn
							flat
							label="Yes"
							color="primary"
							v-close-popup
							v-if="invoiceMailSendingPrompt.mode == 'single'"
							@click="sendInvoiceMail()"
						/>
						<q-btn
							flat
							label="Yes"
							color="primary"
							v-close-popup
							v-if="invoiceMailSendingPrompt.mode == 'multi'"
							@click="sendInvoiceMultiMail()"
						/>
					</q-card-actions>
				</q-card>
			</q-dialog>
		</q-page>
	</q-page-container>
</template>

<script>
	import NavigationBar from '@/components/admin/NavigationBar.vue';
	import { useToast } from 'vue-toastification';
	import moment from 'moment';
	import { PieChart } from 'vue-chart-3';
	import { Chart, registerables } from 'chart.js';
	import { useOrganisationStore } from './../../../store/organisation';

	Chart.register(...registerables);

	export default {
		name: 'CustomeraccountsView',
		data() {
			return {
				customeraccount: {
					id: '',
					type: '',
					number: '',
					gender: '',
					firstname: '',
					lastname: '',
					email: '',
					organisation: '',
					Invoices: [],
				},
				invoicesTableConfiguration: {
					initialPagination: {
						rowsPerPage: 20,
						sortBy: 'invoicedate',
						descending: true,
					},
					columns: [
						{
							name: 'number',
							align: 'center',
							label: 'Number',
							field: 'number',
							sortable: true,
						},
						{
							name: 'invoicedate',
							label: 'Invoice Date',
							field: 'invoicedate',
							sortable: true,
							format: (val) => {
								return moment(val).format('DD.MM.YYYY');
							},
						},
						{
							name: 'amount',
							label: 'Amount',
							field: 'amount',
							sortable: true,
							format: (val) => {
								const eur = new Intl.NumberFormat('de-DE', {
									style: 'currency',
									currency: 'EUR',
								});
								return eur.format(val);
							},
						},
						{
							name: 'status',
							label: 'Status',
							field: 'status',
							sortable: true,
						},
						{
							name: 'actions',
							label: 'Actions',
							field: 'actions',
							sortable: false,
						},
					],
					filter: '',
					selected: [],
					selectedAction: null,
				},
				invoiceDialogSwitch: false,
				invoiceMailSendingDialogSwitch: false,
				selectedCustomerInvoices: [],
				customeraccounts: [],
				selectedCustomerAccountId: null,
				invoiceDialog: {
					id: null,
					status: null,
					number: null,
					amount: null,
					billingdate: null,
					invoicedate: null,
					file: null,
				},
				customeraccountDialog: {
					id: null,
					organisationId: null,
					type: null,
					number: null,
					gender: null,
					firstname: null,
					lastname: null,
					organisation: null,
					email: null,
				},
				chartData: {},
				invoiceMailSendingPrompt: {
					mode: 'single',
					email: '',
					always_sended_single_count: 0,
					always_sended_multi_count: 0,
					selectedItem: null,
				},
			};
		},
		components: {
			NavigationBar,
			PieChart,
		},
		mounted() {
			const customeraccountId = this.$route.params.customeraccountId;

			let that = this;
			const organisationStore = useOrganisationStore();
			organisationStore.getCurrentOrganisation().then((response) => {
				organisationStore
					.getCustomerAccount({
						customeraccountId: customeraccountId,
						organisationId: response,
					})
					.then((response2) => {
						that.customeraccount = response2;

						// build pie chart data
						const chartLabels = ['Paid Invoices', 'Open Invoices'];
						let paid = 0;
						let open = 0;
						for (
							let i = 0;
							i < that.customeraccount.Invoices.length;
							i++
						) {
							if (
								that.customeraccount.Invoices[i].status ==
								'paid'
							) {
								paid++;
							} else {
								open++;
							}
						}
						const invoiceValues = [paid, open];
						this.chartData = {
							labels: chartLabels,
							datasets: [
								{
									label: 'Dataset 1',
									data: invoiceValues,
									backgroundColor: ['#8faef2', '#eeeeee'],
								},
							],
						};
					});
			});
		},
		methods: {
			fileInputHandler(event) {
				//Read File
				var selectedFile = event.target.files;
				//Check File is not Empty
				if (selectedFile.length > 0) {
					// Select the very first file from list
					var fileToLoad = selectedFile[0];
					// FileReader function for read the file.
					var fileReader = new FileReader();
					var base64;
					// Onload of file read the file content
					let that = this;
					fileReader.onload = function (fileLoadedEvent) {
						base64 = fileLoadedEvent.target.result;
						// Print data in console
						that.invoiceDialog.file = base64.split(',')[1];
					};
					// Convert data to base64
					fileReader.readAsDataURL(fileToLoad);
				}
			},
			openInvoiceDialog(invoiceItem) {
				if (invoiceItem == undefined || invoiceItem == null) {
					this.invoiceDialog = {
						id: null,
						status: null,
						number: null,
						amount: null,
						billingdate: null,
						invoicedate: null,
						file: null,
					};
				} else {
					console.log(invoiceItem);
					this.invoiceDialog = invoiceItem;
					if (this.invoiceDialog.billingdate != null) {
						this.invoiceDialog.billingdate = moment(
							this.invoiceDialog.billingdate
						).format('YYYY/MM/DD');
					}
					if (this.invoiceDialog.invoicedate != null) {
						this.invoiceDialog.invoicedate = moment(
							this.invoiceDialog.invoicedate
						).format('YYYY/MM/DD');
					}
				}
				this.invoiceDialogSwitch = true;
			},
			submitInvoiceDialog() {
				if (
					this.invoiceDialog.id == undefined ||
					this.invoiceDialog.id == null
				) {
					this.addInvoice();
				} else {
					this.editInvoice();
				}
			},
			addInvoice() {
				let invoiceData = {
					status: this.invoiceDialog.status.value,
					number: this.invoiceDialog.number,
					amount: this.invoiceDialog.amount,
					billingdate: this.invoiceDialog.billingdate,
					invoicedate: this.invoiceDialog.invoicedate,
					file: this.invoiceDialog.file,
				};

				// Select the very first file from list
				var fileToLoad = this.invoiceDialog.file;
				// FileReader function for read the file.
				var fileReader = new FileReader();
				var base64;
				// Onload of file read the file content
				//let that = this;
				fileReader.onload = function (fileLoadedEvent) {
					base64 = fileLoadedEvent.target.result;
					// Print data in console
					invoiceData.file = base64.split(',')[1];
				};
				// Convert data to base64
				fileReader.readAsDataURL(fileToLoad);

				if (invoiceData.billingdate != null) {
					invoiceData.billingdate = moment(
						invoiceData.billingdate,
						'DD.MM.YYYY'
					).format('YYYY-MM-DD');
				}
				if (invoiceData.invoicedate != null) {
					invoiceData.invoicedate = moment(
						invoiceData.invoicedate,
						'DD.MM.YYYY'
					).format('YYYY-MM-DD');
				}

				let that = this;
				const organisationStore = useOrganisationStore();
				organisationStore.getCurrentOrganisation().then((response) => {
					organisationStore
						.addInvoice({
							organisationId: response,
							customeraccountId: that.customeraccount.id,
							data: invoiceData,
						})
						.then(() => {
							organisationStore
								.getCustomerAccounts(response)
								.then((response2) => {
									that.customeraccounts = response2;
									that.invoiceDialogSwitch = false;
								});
						});
				});
			},
			editInvoice() {
				let invoiceData = {
					id: this.invoiceDialog.id,
					status: this.invoiceDialog.status.value,
					number: this.invoiceDialog.number,
					amount: this.invoiceDialog.amount,
					billingdate: this.invoiceDialog.billingdate,
					invoicedate: this.invoiceDialog.invoicedate,
					file: this.invoiceDialog.file,
				};

				if (invoiceData.billingdate != null) {
					invoiceData.billingdate = moment(
						invoiceData.billingdate,
						'YYYY/MM/DD'
					).format('YYYY-MM-DD 00:00:00');
				}
				if (invoiceData.invoicedate != null) {
					invoiceData.invoicedate = moment(
						invoiceData.invoicedate,
						'YYYY/MM/DD'
					).format('YYYY-MM-DD 00:00:00');
				}

				// TODO add new file handling

				let that = this;
				const organisationStore = useOrganisationStore();
				organisationStore.getCurrentOrganisation().then((response) => {
					organisationStore
						.editInvoice({
							organisationId: response,
							customeraccountId: that.customeraccount.id,
							invoiceId: invoiceData.id,
							data: invoiceData,
						})
						.then(() => {
							organisationStore
								.getCustomerAccounts(response)
								.then((response2) => {
									that.customeraccounts = response2;
									that.invoiceDialogSwitch = false;
								});
						});
				});
			},
			viewInvoice(item) {
				this.$router.push({
					name: 'AdminPdfView',
					params: {
						invoiceId: item.id,
						customeraccountId: item.customeraccount_id,
					},
				});
			},
			deleteInvoice(item) {
				// TODO add prompt
				let that = this;
				const organisationStore = useOrganisationStore();
				organisationStore.getCurrentOrganisation().then((response) => {
					organisationStore
						.deleteInvoice({
							organisationId: response,
							customeraccountId: item.customeraccount_id,
							invoiceId: item.id,
						})
						.then(() => {
							organisationStore
								.getCustomerAccounts(response)
								.then((response2) => {
									that.customeraccounts = response2;
									that.$refs.dialog.hide();
								});
						});
				});
			},
			tableMultiAction() {
				if (this.invoicesTableConfiguration.selectedAction !== null) {
					if (
						this.invoicesTableConfiguration.selectedAction.value ==
						'send_mail'
					) {
						this.sendInvoiceMultiMailDialog();
					}
				}
			},
			sendInvoiceMultiMailDialog() {
				const invoices = [];
				for (
					let i = 0;
					i < this.invoicesTableConfiguration.selected.length;
					i++
				) {
					invoices.push(
						this.invoicesTableConfiguration.selected[i].id
					);
				}

				this.invoiceMailSendingPrompt.selectedItem = {
					customeraccount_id: this.customeraccount.id,
					invoices: invoices,
				};

				let that = this;
				this.invoiceMailSendingPrompt.always_sended_multi_count = 0;
				this.invoiceMailSendingPrompt.always_sended_single_count = 0;
				this.invoiceMailSendingPrompt.mode = 'multi';
				this.invoiceMailSendingPrompt.email =
					this.customeraccount.email;
				const organisationStore = useOrganisationStore();
				organisationStore.getCurrentOrganisation().then((response) => {
					const currentOrganisation = response;

					for (let i = 0; i < invoices.length; i++) {
						organisationStore
							.getInvoiceMailSendedCount({
								organisationId: currentOrganisation,
								customeraccountId: this.customeraccount.id,
								invoiceId: invoices[i],
							})
							.then((response2) => {
								that.invoiceMailSendingPrompt.always_sended_multi_count +=
									response2.count;
							});
					}

					this.invoiceMailSendingDialogSwitch = true;
				});
			},
			sendInvoiceMultiMail() {
				const customeraccount_id =
					this.invoiceMailSendingPrompt.selectedItem
						.customeraccount_id;

				const invoices = [];
				for (
					let i = 0;
					i < this.invoicesTableConfiguration.selected.length;
					i++
				) {
					invoices.push(
						this.invoicesTableConfiguration.selected[i].id
					);
				}

				const organisationStore = useOrganisationStore();
				organisationStore.getCurrentOrganisation().then((response) => {
					organisationStore
						.sendInvoiceMultiMail({
							organisationId: response,
							customeraccountId: customeraccount_id,
							invoices: invoices,
						})
						.then(() => {
							// Get toast interface
							const toast = useToast();

							// or with options
							toast.success('Invoices sended');
						});
				});

				this.invoiceMailSendingDialogSwitch = true;
			},
			sendInvoiceMailDialog(item, customeraccount) {
				this.invoiceMailSendingPrompt.email = customeraccount.email;
				this.invoiceMailSendingPrompt.selectedItem = item;
				this.invoiceMailSendingPrompt.always_sended_multi_count = 0;
				this.invoiceMailSendingPrompt.always_sended_single_count = 0;
				this.invoiceMailSendingPrompt.mode = 'single';

				let that = this;
				const organisationStore = useOrganisationStore();
				organisationStore
					.getInvoiceMailSendedCount({
						organisationId: customeraccount.organisation_id,
						customeraccountId: item.customeraccount_id,
						invoiceId: item.id,
					})
					.then((response) => {
						if (response.count > 0) {
							that.invoiceMailSendingPrompt.always_sended_single_count =
								response.count;
						} else {
							that.invoiceMailSendingPrompt.always_sended_single_count = 0;
						}
					});

				this.invoiceMailSendingDialogSwitch = true;
			},
			sendInvoiceMail() {
				this.invoiceMailSendingDialogSwitch = false;
				const item = this.invoiceMailSendingPrompt.selectedItem;

				const organisationStore = useOrganisationStore();
				organisationStore.getCurrentOrganisation().then((response) => {
					organisationStore
						.sendInvoiceMail({
							organisationId: response,
							customeraccountId: item.customeraccount_id,
							invoiceId: item.id,
						})
						.then(() => {
							// Get toast interface
							const toast = useToast();

							// or with options
							toast.success('Invoice sended');
						});
				});
			},
			// TODO not implemented yet
			openCustomeraccountDialog(customeraccountId = null, data = null) {
				if (customeraccountId != null) {
					this.selectedCustomerAccountId = customeraccountId;
				} else {
					this.selectedCustomerAccountId = null;
				}
				if (data != null) {
					this.customeraccountDialog = data;
				} else {
					this.customeraccountDialog = {};
				}
				this.$refs.customeraccountDialog.show();
			},
			// TODO not implemented yet
			editCustomeraccount() {
				const organisationStore = useOrganisationStore();
				organisationStore
					.updateCustomeraccount({
						organisationId:
							this.customeraccountDialog.organisation_id,
						customeraccountId: this.customeraccountDialog.id,
						data: this.customeraccountDialog,
					})
					.then(() => {
						this.$refs.customeraccountDialog.hide();
					});
			},
		},
	};
</script>
