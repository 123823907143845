<template>
	<q-layout>
		<NavigationBar />

		<q-drawer show-if-above side="left" bordered>
			<q-scroll-area
				style="
					height: calc(100% - 125px);
					margin-top: 125px;
					border-right: 1px solid #ddd;
				"
			>
				<q-list padding>
					<q-item clickable v-ripple>
						<q-item-section avatar>
							<q-icon name="settings" />
						</q-item-section>

						<q-item-section> Settings </q-item-section>
					</q-item>

					<q-item clickable v-ripple disabled>
						<q-item-section avatar>
							<q-icon name="send" />
						</q-item-section>

						<q-item-section> Spaces </q-item-section>
						<q-tooltip
							anchor="center right"
							self="center left"
							:offset="[10, 10]"
							class="bg-amber text-black shadow-4"
						>
							Currently in development
						</q-tooltip>
					</q-item>

					<q-item active clickable v-ripple disabled>
						<q-item-section avatar>
							<q-icon name="star" />
						</q-item-section>

						<q-item-section> Members </q-item-section>
						<q-tooltip
							anchor="center right"
							self="center left"
							:offset="[10, 10]"
							class="bg-amber text-black shadow-4"
						>
							Currently in development
						</q-tooltip>
					</q-item>

					<q-item clickable v-ripple disabled>
						<q-item-section avatar>
							<q-icon name="send" />
						</q-item-section>

						<q-item-section> Teams </q-item-section>
						<q-tooltip
							anchor="center right"
							self="center left"
							:offset="[10, 10]"
							class="bg-amber text-black shadow-4"
						>
							Currently in development
						</q-tooltip>
					</q-item>

					<q-item clickable v-ripple disabled>
						<q-item-section avatar>
							<q-icon name="drafts" />
						</q-item-section>

						<q-item-section> Access Tokens </q-item-section>
						<q-tooltip
							anchor="center right"
							self="center left"
							:offset="[10, 10]"
							class="bg-amber text-black shadow-4"
						>
							Currently in development
						</q-tooltip>
					</q-item>

					<q-item clickable v-ripple disabled>
						<q-item-section avatar>
							<q-icon name="drafts" />
						</q-item-section>

						<q-item-section> Integrations </q-item-section>
						<q-tooltip
							anchor="center right"
							self="center left"
							:offset="[10, 10]"
							class="bg-amber text-black shadow-4"
						>
							Currently in development
						</q-tooltip>
					</q-item>
				</q-list>
			</q-scroll-area>

			<q-img
				class="absolute-top"
				src="https://cdn.quasar.dev/img/material.png"
				style="height: 125px"
			>
				<div class="absolute-bottom bg-transparent">
					<q-avatar size="56px" class="q-mb-sm">
						<img src="https://cdn.quasar.dev/img/boy-avatar.png" />
					</q-avatar>
					<div class="text-weight-bold">{{ organisation.name }}</div>
				</div>
			</q-img>
		</q-drawer>

		<q-page-container>
			<q-page padding>
				<div>
					<q-btn
						dense
						flat
						icon="arrow_back"
						@click="
							this.$router.push({
								name: 'Home',
							})
						"
					/>
				</div>
				<div class="row">
					<div class="col-4"></div>
					<div class="col-4">
						<q-form @submit="updateOrganistion" class="q-gutter-md">
							<q-input
								filled
								v-model="organisation.name"
								label="Name"
								lazy-rules
								:rules="[
									(val) =>
										(val && val.length > 0) ||
										'Please type something',
								]"
							/>

							<q-input
								filled
								v-model="organisation.mailtext"
								type="textarea"
								label="Mailtext"
								lazy-rules
								:rules="[
									(val) =>
										(val !== null && val !== '') ||
										'Please type your Mailtext',
								]"
							/>

							<q-input
								filled
								v-model="organisation.mailfooter"
								type="textarea"
								label="Mailfooter"
								lazy-rules
								:rules="[
									(val) =>
										(val !== null && val !== '') ||
										'Please type your Mailfooter',
								]"
							/>

							<q-input
								filled
								v-model="organisation.mailbcc"
								label="Mail BCC"
								lazy-rules
								:rules="[
									(val) =>
										(val !== null && val !== '') ||
										'Please type your mailbcc',
								]"
							/>

							<div>
								<q-btn
									label="Submit"
									type="submit"
									color="primary"
								/>
							</div>
						</q-form>
					</div>
				</div>
			</q-page>
		</q-page-container>
	</q-layout>
</template>

<script>
	import NavigationBar from '@/components/admin/NavigationBar.vue';
	import { useOrganisationStore } from './../../store/organisation';

	export default {
		name: 'OrganisationsSettings',
		components: {
			NavigationBar: NavigationBar,
		},
		data() {
			return {
				organisation: {
					id: '',
					name: '',
					mailtext: '',
					mailfooter: '',
					mailbcc: '',
				},
			};
		},
		mounted() {
			const organisationStore = useOrganisationStore();
			organisationStore
				.getCurrentOrganisation()
				.then((currentOrganisationId) => {
					organisationStore.getOrganisations().then((response) => {
						for (let i = 0; i < response.length; i++) {
							if (response[i].id == currentOrganisationId) {
								this.organisation = response[i];
							}
						}
					});
				});
		},
		methods: {
			updateOrganistion() {
				const organisationStore = useOrganisationStore();
				organisationStore
					.updateOrganisation(this.organisation)
					.then(() => {
						this.$router.push({ path: '/customeraccounts' });
					});
			},
		},
	};
</script>

<style></style>
