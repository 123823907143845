import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import axios from 'axios';
import VueAxios from 'vue-axios';
import Toast from 'vue-toastification';
import 'vue-toastification/dist/index.css';
import { createI18n } from 'vue-i18n';

import { createPinia } from 'pinia';
const pinia = createPinia();
import { useAuthStore } from './store/auth';
import Quasar from 'quasar/src/vue-plugin.js';;
import quasarUserOptions from './quasar-user-options';

router.beforeEach((to, from, next) => {
	const publicPages = ['/login'];
	const authRequired = !publicPages.includes(to.path);
	const authStore = useAuthStore();
	let currentJwt = authStore.jwt;

	if (authRequired == false || (currentJwt != '' && currentJwt != null)) {
		next();
	} else {
		authStore.refreshJwt().then(() => {
			currentJwt = authStore.jwt;
			if (
				currentJwt != '' &&
				currentJwt != undefined &&
				currentJwt != null
			) {
				next();
			} else {
				next('/login');
			}
		});
	}
});

import messages from './i18n';
const i18n = createI18n({
	locale: 'en', // set locale
	fallbackLocale: 'en', // set fallback locale
	messages, // set locale messages
});

createApp(App)
	.use(Quasar, quasarUserOptions)
	.use(pinia)
	.use(Toast)
	.use(router)
	.use(VueAxios, axios)
	.use(i18n)
	.mount('#app');
