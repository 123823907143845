import { createWebHistory, createRouter } from 'vue-router';
import Home from '@/views/Home.vue';
import Login from '@/views/Login.vue';
import Account from '@/views/Account.vue';
import PdfViewer from '@/views/PdfViewer';
import Settings from '@/views/Settings.vue';
import OrganisationsSettings from '@/views/admin/OrganisationsSettings.vue';
import OrganizationsIndex from '@/views/admin/Organizations/index.vue';
import Customeraccounts from '@/views/admin/Customeraccounts.vue';
import AdminPdfViewer from '@/views/admin/PdfViewer';
import CustomeraccountsView from '@/views/admin/Customeraccounts/view.vue';

const routes = [
	{
		path: '/',
		name: 'Home',
		component: Home,
	},
	{
		path: '/login',
		name: 'Login',
		component: Login,
	},
	{
		path: '/account',
		name: 'Account',
		component: Account,
	},
	{
		path: '/pdfview/:invoiceId',
		name: 'PdfView',
		component: PdfViewer,
	},
	{
		path: '/settings',
		name: 'Settings',
		component: Settings,
	},
	{
		path: '/organizations',
		name: 'OrganizationsIndex',
		component: OrganizationsIndex,
	},
	{
		path: '/organisations/settings',
		name: 'OrganisationsSettings',
		component: OrganisationsSettings,
	},
	{
		path: '/customeraccounts',
		name: 'Customeraccounts',
		component: Customeraccounts,
	},
	{
		path: '/customeraccounts/:customeraccountId',
		name: 'CustomeraccountsView',
		component: CustomeraccountsView,
	},
	{
		path: '/admin/pdfview/:customeraccountId/:invoiceId',
		name: 'AdminPdfView',
		component: AdminPdfViewer,
	},
];

const router = createRouter({
	history: createWebHistory(),
	routes,
});

export default router;
