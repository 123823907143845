<template>
	<NavigationBar />

	<q-page-container>
		<q-page padding>
			<div>
				<q-btn
					dense
					flat
					icon="arrow_back"
					@click="
						this.$router.push({
							name: 'Home',
						})
					"
				/>
			</div>
			<div class="row">
				<div class="col-4"></div>
				<div class="col-4">
					<q-form @submit="updateUser" class="q-gutter-md">
						<q-input
							filled
							v-model="userDialog.username"
							label="E-Mail / Username"
							lazy-rules
							:rules="[
								(val) =>
									(val && val.length > 0) ||
									'Please type something',
							]"
						/>

						<q-input
							filled
							v-model="userDialog.firstname"
							label="Firstname"
							lazy-rules
							:rules="[
								(val) =>
									(val !== null && val !== '') ||
									'Please type your firstname',
							]"
						/>

						<q-input
							filled
							v-model="userDialog.lastname"
							label="Lastname"
							lazy-rules
							:rules="[
								(val) =>
									(val !== null && val !== '') ||
									'Please type your lastname',
							]"
						/>

						<q-input
							filled
							v-model="userDialog.city"
							label="City"
							lazy-rules
							:rules="[
								(val) =>
									(val !== null && val !== '') ||
									'Please type your city',
							]"
						/>

						<q-input
							filled
							v-model="userDialog.country"
							label="Country"
							lazy-rules
							:rules="[
								(val) =>
									(val !== null && val !== '') ||
									'Please type your country',
							]"
						/>

						<q-select
							standout
							v-model="userDialog.language"
							:options="languageOptions"
							label="Language"
						/>

						<div>
							<q-btn
								label="Submit"
								type="submit"
								color="primary"
							/>
						</div>
					</q-form>
				</div>
			</div>
		</q-page>
	</q-page-container>
</template>

<script>
	import NavigationBar from '@/components/NavigationBar.vue';
	import { useUserStore } from './../store/user';

	export default {
		name: 'Settings',
		components: {
			NavigationBar: NavigationBar,
		},
		data() {
			return {
				buttonLoading: false,
				languageOptions: [
					{
						label: 'Deutsch',
						value: 'de',
					},
					{
						label: 'English',
						value: 'en',
					},
				],
				userDialog: {
					username: '',
					firstname: '',
					lastname: '',
					city: '',
					country: '',
					language: '',
				},
			};
		},
		mounted() {
			document.querySelector('html').classList.add('dark');

			const userStore = useUserStore();
			userStore.getUser().then((response) => {
				this.userDialog = response;
				// set language object
				for (let i = 0; i < this.languageOptions.length; i++) {
					if (
						this.languageOptions[i].value ==
						this.userDialog.language
					) {
						this.userDialog.language = this.languageOptions[i];
					}
				}
			});
		},
		methods: {
			updateUser() {
				this.buttonLoading = true;

				let data = {
					username: this.userDialog.username,
					firstname: this.userDialog.firstname,
					lastname: this.userDialog.lastname,
					city: this.userDialog.city,
					country: this.userDialog.country,
					language: this.userDialog.language.value,
				};

				const userStore = useUserStore();
				userStore.updateUser(data).then(() => {
					this.buttonLoading = false;
					this.$router.push({ path: '/' });
				});
			},
		},
	};
</script>

<style></style>
