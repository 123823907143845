<template>
	<NavigationBar />

	<q-page-container>
		<q-page padding>
			<div class="row">
				<div class="col-2"></div>
				<div class="col-8">
					<h5 class="q-mb-md">Organizations</h5>
					<q-card flat bordered>
						<q-card-section>
							<q-list>
								<div
									v-for="organisation in this.organisations"
									:key="organisation.id"
								>
									<q-item>
										<q-item-section>
											{{ organisation.name }}
										</q-item-section>
										<q-item-section side>
											<div>
												<q-btn
													color="primary"
													class="q-mr-md"
													@click="
														this.$router.push({
															path: '/organisations/settings',
														})
													"
													>{{ $t('settings') }}</q-btn
												>
												<q-btn color="red" disabled>{{
													$t('leave')
												}}</q-btn>
											</div>
										</q-item-section>
									</q-item>
									<q-separator
										v-if="
											organisation !==
											this.organisations[
												this.organisations.length - 1
											]
										"
										spaced
										inset
									/>
								</div>
							</q-list>
						</q-card-section>
					</q-card>
				</div>
			</div>
		</q-page>
	</q-page-container>
</template>

<script>
	import NavigationBar from '@/components/admin/NavigationBar.vue';
	import { useOrganisationStore } from './../../../store/organisation';

	export default {
		name: 'Customeraccounts',
		data() {
			return {
				organisations: [],
			};
		},
		components: {
			NavigationBar,
		},
		mounted() {
			const organisationStore = useOrganisationStore();
			organisationStore.getOrganisations().then((response) => {
				this.organisations = response;
			});
		},
		methods: {},
	};
</script>
